import { Button, Box } from "@mui/material";


const NavBarButton = ({text, end}) => {
    let underlineColor = 'background.main';
    let sideBorderColor = 'background.main';
    if ("/" + text.toLowerCase() == window.location.pathname || (text == 'Home' && window.location.pathname == "/")) underlineColor = 'secondary.main';
    if (end) sideBorderColor = 'secondary.main';

    let linkText = "/";
    if (text != "Home") linkText += text.toLowerCase();

    return (
        <Box display="inline-flex" sx={{borderRight: 3, borderRightColor: sideBorderColor, paddingRight: 1.2}}>
            <Box display="inline-flex" sx={{borderBottom: 3, borderBottomColor: underlineColor, paddingTop: 1}}>
                <Button href={linkText} sx={{color: 'primary.light', paddingBottom: 0, paddingTop: 0, padding: 0, '&:hover': {color: 'primary.dark', boxShadow: 'none', backgroundColor: 'background.main'}, '&:focus': {boxShadow: 'none', color: 'primary.dark'}}} disableRipple>{text}</Button>
            </Box>
        </Box>
    );
};

export default NavBarButton;