import React, { useState, useEffect } from "react";
import { Container, Typography, Box, Divider, autocompleteClasses, Button, IconButton } from "@mui/material";
import bgImg from "./images/Purple-Background.webp";
import EmailSignUpForm from "./EmailSignUpForm";
import { Accessible, AssignmentTurnedIn, Campaign, Chat, ExpandMore, Hearing, PeopleAlt } from "@mui/icons-material";

const Home = () => {
    let landingSectionHeight = window.innerHeight;
    if (landingSectionHeight < 700) landingSectionHeight = 700;
    
    return (
        <>
            <Container sx={{backgroundImage: `url(${bgImg})`, backgroundSize: "cover", backgroundPosition: "center", height: landingSectionHeight, overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',}} maxWidth={false} disableGutters>
                <Box sx={{marginLeft: 'auto', marginRight: 'auto', marginTop: {xs: 6, sm: 18}, width: '85vw'}}>
                    <Typography variant="h1" sx={{color: 'primary.dark'}}>Get Campaigning Today</Typography>
                    <Typography variant="h3">
                        Sign up to our mailing list and we'll handle the rest!
                    </Typography>
                    <Box marginTop={5}>
                        <EmailSignUpForm />
                    </Box>
                    
                </Box>

                <Box>
                    <Typography variant="h2" sx={{color: 'primary.dark'}}>Need Convincing?</Typography>
                    <ExpandMore sx={{height: 48, width: 48, color: 'primary.dark'}} />
                </Box>

            </Container>

            <Container sx={{borderTop: 3, borderColor: 'primary.main', overflow: 'hidden', padding: 0}} maxWidth={false} disableGutters>
                <Box sx={{marginLeft: 'auto', marginRight: 'auto', display: {xs: 'block', md: 'flex'}, justifyContent: 'space-evenly', alignItems: 'center'}}>
                    <Box sx={{width: {xs: '100%', md: '50%'}, height: 700, backgroundColor: 'primary.light', color: 'background.main', padding: {xs: 2, md: 12}, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <Typography variant="h2" >Be a Force for Positive Change</Typography>
                        <Typography variant="p">The Purple Collective empowers you to take clear, actionable steps that collectively drive significant change. By participating in our campaigns, you can help address pressing issues such as accessible housing, inclusive social care, and equitable air travel. Your involvement makes a tangible impact, contributing to a more inclusive and accessible world for all.</Typography>
                    </Box>
                    <Box sx={{width: {xs: '100%', md: '50%'}, height: 700, backgroundColor: 'primary.dark', display: {xs: 'none', md: 'flex'}, justifyContent: 'center', alignItems: 'center'}}>
                        <Campaign aria-label="an icon depicting a megaphone" sx={{fontSize: 520, color: 'background.main', transform: 'rotate(330deg)'}} />
                    </Box>

                    
                    
                </Box>

                <Box sx={{marginLeft: 'auto', marginRight: 'auto', display: {xs: 'block', md: 'flex'}, justifyContent: 'space-evenly', alignItems: 'center'}}>
                    <Box sx={{width: {xs: '100%', md: '50%'}, height: 700, backgroundColor: 'primary.dark', display: {xs: 'none', md: 'flex'}, justifyContent: 'center', alignItems: 'center'}}>
                        <AssignmentTurnedIn aria-label="an icon depicting a clipboard with a tick" sx={{fontSize: 520, color: 'background.main', transform: 'rotate(350deg)'}} />
                    </Box>
                    <Box sx={{width: {xs: '100%', md: '50%'}, height: 700, backgroundColor: 'background.main', padding: {xs: 2, md: 12}, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <Typography variant="h2" sx={{color: 'primary.dark'}}>Participate in Easy and Manageable Actions</Typography>
                        <Typography variant="p">We understand that everyone has busy lives, which is why our tasks are designed to be easily achievable within a working day. Whether it’s signing a petition, sharing important information on social media, or writing to policymakers, each action is straightforward and impactful. You can make a difference without feeling overwhelmed or overcommitted.</Typography>
                    </Box>
                    <Box sx={{width: {xs: '100%', md: '50%'}, height: 700, backgroundColor: 'primary.dark', display: {xs: 'none', md: 'none'}}}>
                        
                    </Box>
                </Box>

                <Box sx={{marginLeft: 'auto', marginRight: 'auto', display: {xs: 'block', md: 'flex'}, justifyContent: 'space-evenly', alignItems: 'center'}}>
                    <Box sx={{width: {xs: '100%', md: '50%'}, height: 700, backgroundColor: 'secondary.main', color: 'background.main', padding: {xs: 2, md: 12}, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <Typography variant="h2">Stay Informed and Engaged</Typography>
                        <Typography variant="p">As a member of the Purple Collective, you’ll receive regular updates on our campaigns, progress, and opportunities to get involved. Our clear and engaging communications ensure you’re always in the loop and can participate in actions that matter to you!</Typography>
                    </Box>
                    <Box sx={{width: {xs: '100%', md: '50%'}, height: 700, backgroundColor: 'primary.dark', display: {xs: 'none', md: 'flex', justifyContent: 'center', alignItems: 'center'}}}>
                        <Chat aria-label="an icon depicting a text message" sx={{fontSize: 520, color: 'background.main'}} />
                    </Box>

                    
                    
                </Box>
            </Container>

            <Container sx={{marginBottom: 3, overflow: 'hidden'}} maxWidth={false} disableGutters>
                
            </Container>

            <Container sx={{marginBottom: 3, overflow: 'hidden'}} maxWidth={false} disableGutters>
                <Box sx={{marginLeft: 'auto', marginRight: 'auto', marginTop: 4, width: '85vw', display: {xs: 'block', md: 'flex'}, justifyContent: 'space-evenly', alignItems: 'center'}}>
                    <Box sx={{marginLeft: 'auto', marginRight: 'auto' , width: {xs: '80vw', md: '60vw'}, borderRight: {xs: 0, md: 1}, borderColor: 'secondary.main'}}>
                        <Typography variant="h2" sx={{color: 'primary.dark'}}>Join Us Today</Typography>
                        <EmailSignUpForm />
                    </Box>
                    <Box sx={{width: {xs: '80vw', md: '20vw'}}}>
                        <IconButton aria-label="Go to about page" href="/about" sx={{color: 'primary.light', marginRight: 0.4, '&:hover': {color: 'primary.dark', backgroundColor: 'background.main'}, display: {xs: 'none', md: 'flex'}}}>
                            <PeopleAlt sx={{height: 212, width: 212}} />
                        </IconButton>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default Home;