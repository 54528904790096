import React, { useState } from "react";
import { AppBar, Toolbar, Typography, Button, IconButton, Box } from "@mui/material";
import NavBarButton from "./NavBarButton";
import PageNavDrawer from "./PageNavDrawer";
import { Instagram, Menu as MenuIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import purpleCollectiveLogoBig from "./images/purple-collective-logo-big.png";
import purpleCollectiveLogoSmall from "./images/purple-collective-logo-small.png";

const NavBar = () => {
    let navBarHeight = 75.06;

    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (openValue) => () => {
      setDrawerOpen(openValue);
    };

    const openExternalInstagramTab = () => {
      window.open('https://www.instagram.com/purplecollectiveglobal/', '_blank');
    }

    return (
        <AppBar aria-label="Navigation Bar" sx={{borderBottom: 3, borderColor: 'primary.main', boxShadow: 0, backgroundColor: 'background.main', paddingBottom: 0, height: navBarHeight, zIndex: 1400, position: {xs: 'sticky', sm: 'fixed'}}}>
            <Toolbar aria-label="" sx={{justifyContent: "space-between", paddingLeft: {xs: 1.7, sm: 3}, paddingRight: 0.4, paddingTop: 1, height: 64}} disableGutters>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <IconButton href="/" sx={{display: {xs: 'none', sm: 'flex'}, margin: 0, padding: 0, '&:hover': {backgroundColor: 'transparent'}}} disableRipple disableFocusRipple>
                <img alt="A small logo with a megaphone and the letter C" src={purpleCollectiveLogoSmall} height={60} style={{marginRight: 10}} />
                </IconButton>

                <IconButton href="/" sx={{display: {xs: 'flex', sm: 'none'}, margin: 0, marginLeft: 1.3, padding: 0, '&:hover': {backgroundColor: 'transparent'}}} disableRipple disableFocusRipple>
                <img alt="A logo with a megaphone next to the letter C. On the left of the logo is the word 'purple' and on the right is the word 'collective'" src={purpleCollectiveLogoBig} height={60} style={{marginRight: 10}} />
                </IconButton>
              
              <Button href="/" aria-label="the purple collective: Return to home page" sx={{display: {xs: 'none', sm: 'flex'} , marginTop: {xs: 1.5, sm: 1.9}, padding: 0, fontSize: {xs: '1.5rem', sm: '2.4rem'}, fontWeight: 900, alignSelf: 'center', '&:hover': {backgroundColor: 'transparent'}, minHeight: 0, minWidth: 0, lineHeight: 0.5}} color='primary' disableRipple>THE PURPLE COLLECTIVE</Button>
              </Box>
              <Box className="NavBarButtons" sx={{ display: {xs: 'none', md: 'flex'}}}>
                <NavBarButton text={'Home'} />
                <NavBarButton text={'About'} end={true} />
                <IconButton onClick={openExternalInstagramTab} sx={{color: 'primary.light', marginTop: 1, marginBottom: 0.7, marginLeft: 1, '&:hover': {color: 'primary.dark', backgroundColor: 'background.main'}, alignSelf: 'baseline'}} aria-label="instagram">
                  <Instagram sx={{width: 33, height: 33}} />
                </IconButton>
              </Box>
              <IconButton aria-label="Navigation menu button" sx={{color: 'primary.light', marginRight: 0.4, '&:hover': {color: 'primary.dark', backgroundColor: 'background.main'}, display: {xs: 'flex', md: 'none'}, alignSelf: 'self-start'}} onClick={toggleDrawer(!drawerOpen)} >
                <MenuIcon sx={{ width: 48, height: 48}} />
              </IconButton>
            </Toolbar>
            <PageNavDrawer toggleDrawer={toggleDrawer} open={drawerOpen} setOpen={setDrawerOpen} />
        </AppBar>
    );
};

export default NavBar;