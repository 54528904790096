import React from "react";
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container } from '@mui/material';


const PrivacyPolicy = () => {

    return (
        <Container sx={{textAlign: 'left', marginTop: {xs: 3, sm: 15}, marginBottom: 3}}>

<Typography variant="h2">PRIVACY POLICY</Typography><br /><br />
<Typography variant="p">Last updated July 19, 2024</Typography><br /><br />
<Typography variant="p">
    This privacy notice for The Purple Collective ('we', 'us', or 'our'), describes how and why we might collect, store, use, and/or share ('process') your information when you use our services ('Services'), such as when you:
</Typography><br /><br />
<Typography variant="p">
    • Visit our website at <a href="http://www.purplecollectiveglobal.com">http://www.purplecollectiveglobal.com</a>
</Typography><br /><br />
<Typography variant="p">
    • Engage with us in other related ways, including any sales, marketing, or events
</Typography><br /><br />
<Typography variant="p">
    Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href="mailto:hello@purplecollectiveglobal.com">hello@purplecollectiveglobal.com</a>.
</Typography><br /><br />

<Typography variant="h2">SUMMARY OF KEY POINTS</Typography><br /><br />
<Typography variant="p">
    This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.
</Typography><br /><br />
<Typography variant="p">
<strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.
</Typography><br /><br />
<Typography variant="p">
<strong>Do we process any sensitive personal information?</strong> We do not process sensitive personal information.
</Typography><br /><br />
<Typography variant="p">
<strong>Do we collect any information from third parties?</strong> We do not collect any information from third parties.
</Typography><br /><br />
<Typography variant="p">
<strong>How do we process your information?</strong> We process your information to provide, improve, and administer our services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.
</Typography><br /><br />
<Typography variant="p">
<strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information.
</Typography><br /><br />
<Typography variant="p">
<strong>How do we keep your information safe?</strong> We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.
</Typography><br /><br />
<Typography variant="p">
<strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.
</Typography><br /><br />
<Typography variant="p">
<strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by contacting us at <a href="mailto:hello@purplecollectiveglobal.com">hello@purplecollectiveglobal.com</a>. We will consider and act upon any request in accordance with applicable data protection laws.
</Typography><br /><br />
<Typography variant="p">
<strong>Want to learn more about what we do with any information we collect?</strong> Review the privacy notice in full.
</Typography><br /><br />

<Typography variant="h2">TABLE OF CONTENTS</Typography><br /><br />
<Typography variant="p">1. WHAT INFORMATION DO WE COLLECT?</Typography><br /><br />
<Typography variant="p">2. HOW DO WE PROCESS YOUR INFORMATION?</Typography><br /><br />
<Typography variant="p">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</Typography><br /><br />
<Typography variant="p">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</Typography><br /><br />
<Typography variant="p">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</Typography><br /><br />
<Typography variant="p">6. HOW LONG DO WE KEEP YOUR INFORMATION?</Typography><br /><br />
<Typography variant="p">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</Typography><br /><br />
<Typography variant="p">8. WHAT ARE YOUR PRIVACY RIGHTS?</Typography><br /><br />
<Typography variant="p">9. CONTROLS FOR DO-NOT-TRACK FEATURES</Typography><br /><br />
<Typography variant="p">10. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</Typography><br /><br />
<Typography variant="p">11. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</Typography><br /><br />
<Typography variant="p">12. DO WE MAKE UPDATES TO THIS NOTICE?</Typography><br /><br />
<Typography variant="p">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Typography><br /><br />
<Typography variant="p">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</Typography><br /><br />

<Typography variant="h2">1. WHAT INFORMATION DO WE COLLECT?</Typography><br /><br />
<Typography variant="h2">Personal information you disclose to us</Typography><br /><br />
<Typography variant="p">In Short: We collect personal information that you provide to us.</Typography><br /><br />
<Typography variant="p">
    We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
</Typography><br /><br />
<Typography variant="p">
    Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
</Typography><br /><br />
<Typography variant="p">• email addresses</Typography><br /><br />
<Typography variant="p">Sensitive Information. We do not process sensitive information.</Typography><br /><br />
<Typography variant="p">
    All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
</Typography><br /><br />
<Typography variant="h2">Information automatically collected</Typography><br /><br />
<Typography variant="p">
    In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
</Typography><br /><br />
<Typography variant="p">
    We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
</Typography><br /><br />
<Typography variant="p">The information we collect includes:</Typography><br /><br />
<Typography variant="p">
    • Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).
</Typography><br /><br />
<Typography variant="p">
    • Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
</Typography><br /><br />

<Typography variant="p">• Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise.</Typography>

<Typography variant="p">How much information we collect depends on the type and settings of the device you use to access the Services.</Typography>

<Typography variant="p">For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address).</Typography>

<Typography variant="p">You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device.</Typography>

<Typography variant="p">However, if you choose to opt out, you may not be able to use certain aspects of the Services.</Typography><br /><br />

<Typography variant="h2">2. HOW DO WE PROCESS YOUR INFORMATION?</Typography><br /><br />
<Typography variant="p">In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law.</Typography><br /><br />
<Typography variant="p">We may also process your information for other purposes with your consent.</Typography><br /><br />
<Typography variant="p">We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</Typography><br /><br />
<Typography variant="p">• To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</Typography><br /><br />
<Typography variant="p">• To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual's vital interest, such as to prevent harm.</Typography><br /><br />
<Typography variant="h2">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</Typography><br /><br />
<Typography variant="p">In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.</Typography><br /><br />
<Typography variant="p">If you are located in the EU or UK, this section applies to you.</Typography><br /><br />
<Typography variant="p">The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</Typography><br /><br />
<Typography variant="p">• Consent. We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about withdrawing your consent.</Typography><br /><br />
<Typography variant="p">• Performance of a Contract. We may process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</Typography><br /><br />
<Typography variant="p">• Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</Typography><br /><br />
<Typography variant="p">• Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</Typography><br /><br />
<Typography variant="p">If you are located in Canada, this section applies to you.</Typography><br /><br />
<Typography variant="p">We may process your information if you have given us specific permission (i.e. express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e. implied consent). You can withdraw your consent at any time.</Typography><br /><br />
<Typography variant="p">In some exceptional cases, we may be legally permitted under applicable law to process your Information without your consent, including, for example:</Typography><br /><br />
<Typography variant="p">• If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</Typography><br /><br />
<Typography variant="p">• For investigations and fraud detection and prevention</Typography><br /><br />
<Typography variant="p">• For business transactions provided certain conditions are met</Typography><br /><br />
<Typography variant="p">• If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</Typography><br /><br />
<Typography variant="p">• For identifying injured, ill, or deceased persons and communicating with next of kin</Typography><br /><br />
<Typography variant="p">• If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</Typography><br /><br />
<Typography variant="p">• If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</Typography><br /><br />
<Typography variant="p">• If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records.</Typography><br /><br />
<Typography variant="p">• If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced.</Typography><br /><br />
<Typography variant="p">• If the collection is solely for journalistic, artistic, or literary purposes.</Typography><br /><br />
<Typography variant="p">• If the information is publicly available and is specified by the regulations.</Typography><br /><br />
<Typography variant="h2">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</Typography><br /><br />
<Typography variant="p">In Short: We may share information in specific situations described in this section and/or with the following third parties.</Typography><br /><br />
<Typography variant="p">We may need to share your personal information in the following situations:</Typography><br /><br />
<Typography variant="p">• Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</Typography><br /><br />

<Typography variant="h2">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</Typography><br /><br />

<Typography variant="p">In Short: We may use cookies and other tracking technologies to collect and store your information.</Typography><br /><br />

<Typography variant="p">We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.</Typography><br /><br />

<Typography variant="p">We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.</Typography><br /><br />

<Typography variant="p">To the extent these online tracking technologies are deemed to be a 'sale'/'sharing' (which includes targeted advertising, as defined under the applicable laws) under applicable US state laws, you can opt out of these online tracking technologies by submitting a request as described below under section 'DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?'</Typography><br /><br />

<Typography variant="p">Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</Typography><br /><br />

<Typography variant="p">Google Analytics</Typography><br /><br />

<Typography variant="p">We may share your information with Google Analytics to track and analyze the use of the services. The Google Analytics Advertising Features that we may use include Google Analytics Demographics and Interests Reporting, Remarketing with Google Analytics, and Google Display Network Impressions Reporting. To opt out of being tracked by Google Analytics across the Services, visit <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>. You can opt out of Google Analytics Advertising Features through Ads Settings and Ad Settings for mobile apps. Other opt out means include <a href="http://optout.networkadvertising.org/">http://optout.networkadvertising.org/</a> and <a href="http://www.networkadvertising.org/mobile-choice">http://www.networkadvertising.org/mobile-choice</a>. For more information on the privacy practices of Google, please visit the Google Privacy & Terms page.</Typography><br /><br />

<Typography variant="h2">6. HOW LONG DO WE KEEP YOUR INFORMATION?</Typography><br /><br />

<Typography variant="p">In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</Typography><br /><br />

<Typography variant="p">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).</Typography><br /><br />

<Typography variant="p">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</Typography><br /><br />

<Typography variant="h2">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</Typography><br /><br />

<Typography variant="p">In Short: We aim to protect your personal information through a system of organizational and technical security measures.</Typography><br /><br />

<Typography variant="p">We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</Typography><br /><br />

<Typography variant="h2">8. WHAT ARE YOUR PRIVACY RIGHTS?</Typography><br /><br />

<Typography variant="p">In Short: Depending on your state of residence in the US or in some regions, such as the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.</Typography><br /><br />

<Typography variant="p">In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right:</Typography><br /><br />

<Typography variant="p">• to request access and obtain a copy of your personal information,</Typography><br /><br />
<Typography variant="p">• to request rectification or erasure,</Typography><br /><br />
<Typography variant="p">• to restrict the processing of your personal information,</Typography><br /><br />
<Typography variant="p">• if applicable, to data portability, and</Typography><br /><br />
<Typography variant="p">• not to be subject to automated decision-making.</Typography><br /><br />

<Typography variant="p">In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.</Typography><br /><br />

<Typography variant="p">We will consider and act upon any request in accordance with applicable data protection laws.</Typography><br /><br />

<Typography variant="p">If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or UK data protection authority. If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.</Typography><br /><br />

<Typography variant="p">Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</Typography><br /><br />

<Typography variant="p">Opting out of marketing and promotional communications: You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</Typography><br /><br />

<Typography variant="p">If you have questions or comments about your privacy rights, you may email us at hello@purplecollectiveglobal.com.</Typography><br /><br />

<Typography variant="h2">9. CONTROLS FOR DO-NOT-TRACK FEATURES</Typography><br /><br />

<Typography variant="p">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</Typography><br /><br />

<Typography variant="p">California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognizing or honoring DNT signals, we do not respond to them at this time.</Typography><br /><br />

<Typography variant="h2">10. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</Typography><br /><br />

<Typography variant="p"><strong>In Short:</strong> If you are a resident of California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Montana, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to request access to and receive details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. More information is provided below.</Typography><br /><br />

<Typography variant="p"><strong>Categories of Personal Information We Collect</strong><br /><br />
We have collected the following categories of personal information in the past twelve (12) months:</Typography><br /><br />










    
      <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h6">Category</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Examples</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Collected</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="p">A. Identifiers</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">YES</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="p">B. Personal information as defined in the California Customer Records statute</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">Name, contact information, education, employment, employment history, and financial information</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">NO</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="p">C. Protected classification characteristics under state or federal law</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">NO</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="p">D. Commercial information</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">Transaction information, purchase history, financial details, and payment information</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">NO</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="p">E. Biometric information</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">Fingerprints and voiceprints</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">NO</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="p">F. Internet or other similar network activity</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">NO</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="p">G. Geolocation data</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">Device location</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">NO</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="p">H. Audio, electronic, sensory, or similar information</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">Images and audio, video or call recordings created in connection with our business activities</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">NO</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="p">I. Professional or employment-related information</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">NO</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="p">J. Education Information</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">Student records and directory information</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">NO</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="p">K. Inferences drawn from collected personal information</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual's preferences and characteristics</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">NO</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="p">L. Sensitive personal Information</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p"></Typography>
            </TableCell>
            <TableCell>
              <Typography variant="p">NO</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <Typography variant="p">
    We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
</Typography><br /><br />

<Typography variant="p">
    • Receiving help through our customer support channels;<br /><br />
    • Participation in customer surveys or contests; and<br /><br />
    • Facilitation in the delivery of our Services and to respond to your inquiries.
</Typography><br /><br />

<Typography variant="p">
    We will use and retain the collected personal information as needed to provide the Services or for:
</Typography><br /><br />

<Typography variant="p">
    • Category A - As long as the user has an account with us
</Typography><br /><br />

<Typography variant="p">
    <strong>Sources of Personal Information</strong><br /><br />
    Learn more about the sources of personal information we collect in 'WHAT INFORMATION DO WE COLLECT?'
</Typography><br /><br />

<Typography variant="p">
    <strong>How We Use and Share Personal Information</strong><br /><br />
    Learn about how we use your personal information in the section, 'HOW DO WE PROCESS YOUR INFORMATION?'
</Typography><br /><br />

<Typography variant="p">
    <strong>Will your information be shared with anyone else?</strong><br /><br />
    We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, 'WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?'
</Typography><br /><br />

<Typography variant="p">
    We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be 'selling' of your personal information.
</Typography><br /><br />

<Typography variant="p">
    We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
</Typography><br /><br />

<Typography variant="p">
    <strong>Your Rights</strong><br /><br />
    You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:
</Typography><br /><br />

<Typography variant="p">
    • Right to know whether or not we are processing your personal data<br /><br />
    • Right to access your personal data<br /><br />
    • Right to correct inaccuracies in your personal data<br /><br />
    • Right to request the deletion of your personal data<br /><br />
    • Right to obtain a copy of the personal data you previously shared with us<br /><br />
    • Right to non-discrimination for exercising your rights<br /><br />
    • Right to opt out of the processing of your personal data if it is used for targeted advertising (or sharing as defined under California's privacy law), the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ('profiling')
</Typography><br /><br />

<Typography variant="p">
    Depending upon the state where you live, you may also have the following rights:
</Typography><br /><br />

<Typography variant="p">
    • Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including California's and Delaware's privacy law)<br /><br />
    • Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Oregon's privacy law)<br /><br />
    • Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including California's privacy law)<br /><br />
    • Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida's privacy law)
</Typography><br /><br />

<Typography variant="h2">How to Exercise Your Rights</Typography><br /><br />

<Typography variant="p">
    To exercise these rights, you can contact us by emailing us at hello@purplecollectiveglobal.com, or by referring to the contact details at the bottom of this document.
</Typography><br /><br />

<Typography variant="p">
    Under certain US state data protection laws, you can designate an authorised agent to make a request on your behalf. We may deny a request from an authorised agent that does not submit proof that they have been validly authorised to act on your behalf in accordance with applicable laws.
</Typography><br /><br />

<Typography variant="h2">Request Verification</Typography><br /><br />

<Typography variant="p">
    Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.
</Typography><br /><br />

<Typography variant="p">
    If you submit the request through an authorised agent, we may need to collect additional information to verify your identity before processing your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.
</Typography><br /><br />

<Typography variant="h2">Appeals</Typography><br /><br />

<Typography variant="p">
    Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing us at hello@purplecollectiveglobal.com. We will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may submit a complaint to your state attorney general.
</Typography><br /><br />

<Typography variant="h2">California 'Shine The Light' Law</Typography><br /><br />

<Typography variant="p">
    California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us by using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
</Typography><br /><br />

<Typography variant="h2">11. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</Typography>
<Typography variant="p">In Short: You may have additional rights based on the country you reside in.</Typography><br /><br />

<Typography variant="h2">Australia and New Zealand</Typography>
<Typography variant="p">
    We collect and process your personal information under the obligations and conditions set by Australia's Privacy Act 1988 and New Zealand's Privacy Act 2020 (Privacy Act).
</Typography><br /><br />

<Typography variant="p">
    This privacy notice satisfies the notice requirements defined in both Privacy Acts, in particular: what personal information we collect from you, from which sources, for which purposes, and other recipients of your personal information.
</Typography><br /><br />

<Typography variant="p">
    If you do not wish to provide the personal information necessary to fulfil their applicable purpose, it may affect our ability to provide our services, in particular:<br /><br />
    • offer you the products or services that you want<br /><br />
    • respond to or help with your requests
</Typography><br /><br />

<Typography variant="p">
    At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the contact details provided in the section 'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?'
</Typography><br /><br />

<Typography variant="p">
    If you believe we are unlawfully processing your personal information, you have the right to submit a complaint about a breach of the Australian Privacy Principles to the Office of the Australian Information Commissioner and a breach of New Zealand's Privacy Principles to the Office of New Zealand Privacy Commissioner.
</Typography><br /><br />

<Typography variant="h2">Republic of South Africa</Typography>
<Typography variant="p">
    At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the contact details provided in the section 'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?'
</Typography><br /><br />

<Typography variant="p">
    If you are unsatisfied with the manner in which we address any complaint with regard to our processing of personal information, you can contact the office of the regulator, the details of which are:<br /><br />
    The Information Regulator (South Africa)<br /><br />
    General enquiries: enquiries@inforegulator.org.za<br /><br />
    Complaints (complete POPIA/PAIA form 5):
</Typography><br />
<Typography variant="p" sx={{fontSize: '1.2rem'}}>PAIAComplaints@inforegulator.org.za & POPIAComplaints@inforegulator.org.za</Typography>

<Typography variant="h2">12. DO WE MAKE UPDATES TO THIS NOTICE?</Typography>
<Typography variant="p">
    In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
</Typography><br /><br />

<Typography variant="p">
    We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date at the top of this privacy notice. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
</Typography><br /><br />

<Typography variant="h2">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Typography>
<Typography variant="p">
    If you have questions or comments about this notice, you may email us at hello@purplecollectiveglobal.com
</Typography><br /><br />

<Typography variant="h2">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</Typography>
<Typography variant="p">
    Based on the applicable laws of your country or state of residence in the US, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please email us at hello@purplecollectiveglobal.com.
</Typography>






        </Container>
    )
}

export default PrivacyPolicy;