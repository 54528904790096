import { TaskAlt } from "@mui/icons-material";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

const EmailSignUpForm = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const cooldown_period = 30 * 1000;

    const [displayForm, setDisplayForm] = useState('block');
    const [displayProgress, setDisplayProgress] = useState('none');
    const [displayCheck, setDisplayCheck] = useState('none');
    const [displayError, setDisplayError] = useState('none');

    const handleSubmit = async (e) => {
        e.preventDefault();

        setMessage('');
        setDisplayForm('none');
        setDisplayProgress('block');
        setDisplayError('none');

        const lastSubmitTime = localStorage.getItem('lastSubmitTime');

        if (lastSubmitTime && Date.now() - lastSubmitTime < cooldown_period) {
            setDisplayError('block');
            setDisplayProgress('none');
            setDisplayForm('block');

            setMessage('Please wait before submitting again.');
            setEmail('');
            return;
        }
        

        const response = await fetch('./wordpress-backend/wp-json/simplemailerliteplugin/v1/subscribe', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
        credentials: 'include' // Ensures that cookies are sent
        });
        
        const data = await response.json();
        
        if (data.success) {
            setDisplayProgress('none');
            setDisplayCheck('block');

            setMessage('Welcome to the collective!');
            localStorage.setItem('lastSubmitTime', Date.now());
        } else {
            setDisplayProgress('none');
            setDisplayForm('block');
            setDisplayError('block');

            setMessage('There was an error. Please try again.');
        }

        setEmail('');
    };

    return (
        <Box sx={{height: {xs: 260, md: 170}, overflowY: 'hidden'}}>
            <Box component="form" marginTop={3} marginBottom={3} sx={{display: displayForm}} >
                <TextField onKeyDown={(e) => {if (e.key === 'Enter') {e.preventDefault(); handleSubmit(e);}}} variant="outlined" label="Email" placeholder="Your Email.." onChange={(e) => setEmail(e.target.value)} sx={{borderColor: 'primary.main', backgroundColor: 'background.main', borderRadius: 1, width: {xs: '90%', md: "70%"}}} />
                <Button sx={{width: {xs: '84%', md: "17%"}, backgroundColor: "secondary.main", color: "background.main", marginTop: {xs: 2, md: 0}, marginRight: {xs: 2, md: 0}, marginBottom: {xs: 2, md: 0}, marginLeft: 2, borderRadius: 3 , '&:hover': {backgroundColor: "secondary.light"}}} onClick={handleSubmit}>Sign Up!</Button>
            </Box>
            <CircularProgress sx={{display: displayProgress, marginLeft: 'auto', marginRight: 'auto', marginTop: 3, color: 'primary.dark'}} />
            <Typography variant="p" sx={{display: displayError, color: "primary.dark"}}>{message}</Typography>
            <Box maxWidth={500} sx={{display: displayCheck, marginLeft: 'auto', marginRight: 'auto', width: '100%', padding: 0.7, borderRadius: 2, border: 2, backgroundColor: 'rgba(255,255,255,0.6)'}}>
                <TaskAlt sx={{display: displayCheck, marginLeft: 'auto', marginRight: 'auto', color: 'primary.dark', width: 45, height: 45}} />
                <Typography variant="h2" sx={{color: "primary.dark"}}>{message}</Typography>
                <Typography variant="p" sx={{display: displayCheck}}>Check your inbox for a welcome email</Typography>
            </Box>
        </Box>
    )
}

export default EmailSignUpForm;