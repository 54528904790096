import React from "react";
import { Container, Typography, Box, Divider, autocompleteClasses, Button, IconButton } from "@mui/material";
import { Accessible, ExpandMore, Hearing, PeopleAlt } from "@mui/icons-material";
import martynPhoto from "./images/MartynPhoto.jpg";
import philPhoto from "./images/PhilPhoto.jpg";
import FAQ from "./FAQ";
import EmailSignUpForm from "./EmailSignUpForm";

const About = () => {

    return (
        <>
            <Container sx={{marginTop: {xs: 3, sm: 17}, marginBottom: 3}} maxWidth={false} disableGutters>
                <Box sx={{marginLeft: 'auto', marginRight: 'auto', marginTop: {xs: 4, sm: 30}, width: '85vw', display: {xs: 'block', md: 'flex'}, justifyContent: 'space-evenly', alignItems: 'center'}}>
                    <Accessible aria-label="an icon depicting a person in a wheelchair" sx={{height: {xs: 200, md: 320}, width: {xs: 200, md: 320}, color: 'primary.main'}} />
                    <Box sx={{marginBottom: 4}}>
                        <Typography variant="h1" sx={{color: 'primary.dark'}}>Who Are We?</Typography>
                        <Typography variant="p">The Purple Collective is a groundbreaking community platform designed to unite activists and drive positive change in the disability space. By connecting passionate individuals with impactful campaigns, we empower our community to take clear, actionable steps towards a more inclusive and accessible world.</Typography>
                    </Box>
                </Box>

                <Divider aria-hidden="true" variant="middle" sx={{borderColor: 'secondary.main', margin: 12, marginTop: {xs: 12, sm: 30}, marginBottom: {xs: 12, sm: 30}}} />
                
                <Box sx={{marginLeft: 'auto', marginRight: 'auto', marginTop: 4, marginBottom: {xs: 0, sm: 25}, width: '85vw', display: {xs: 'block', md: 'flex'}, justifyContent: 'space-evenly', alignItems: 'center'}}>
                    <Box sx={{marginBottom: 4}}>
                    <Hearing sx={{height: 200, width: 200, color: 'primary.main', display: {xs: '-moz-initial', md: 'none'}}} />
                        <Typography variant="h1" sx={{color: 'primary.dark'}}>Our Mission Statement</Typography>
                        <Typography variant="p">To connect  activists with campaigns and push for positive change in the disability space through clear actionable goals. We act as the middle party that ensures that activists know that they have something that they can do, which does not require an unreasonable amount of work from them, to help a good cause.</Typography>
                    </Box>
                    <Hearing aria-label="an icon depicting an ear" sx={{height: 320, width: 320, color: 'primary.main', display: {xs: 'none', md: 'block'}}} />
                </Box>
            </Container>

            <Container sx={{marginTop: 8, marginBottom: 3, overflow: 'hidden', backgroundColor: 'primary.light', color: 'background.main', paddingTop: 3, paddingBottom: {xs: 5, sm: 20}}} maxWidth={false} disableGutters>
                <Typography variant="h1">Founders</Typography>
                <Box sx={{marginLeft: 'auto', marginRight: 'auto', marginTop: {xs: 4, sm: 14}, width: '92vw', display: {xs: 'block', md: 'flex'}, justifyContent: 'space-evenly', alignItems: 'flex-start'}}>
                    <Box>
                        <img alt="Headshot of Martyn Sibley" src={martynPhoto} width={250} height={250}  style={{borderRadius: 10, orderWidth: '3px', borderStyle: 'solid', color: "#8F006D"}} />
                        <Box sx={{marginBottom: 4, margin: 1}}>
                            <Typography variant="h2">Martyn Sibley</Typography>
                            <Divider aria-hidden="true" variant="middle" sx={{marginBottom: 1, borderColor: '#BBBBBB'}} />
                            <Typography variant="p">Martyn is a prominent disability inclusion advocate, co-founder, and CEO of Purple Goat, a pioneering disability marketing agency. With over a decade of experience in creating impactful content and driving inclusive initiatives, Martyn has been a leading voice in promoting accessibility and disability rights.<br /> <br />Martyn’s vision is to bridge the gap between disabled and non-disabled communities, fostering a more inclusive and understanding society. Through his work with Purple Goat, Purple Collective, and various media engagements, he continues to champion disability inclusion, demonstrating that accessibility benefits everyone.</Typography>
                        </Box>
                    </Box>
                    
                    <Divider aria-hidden="true" orientation="vertical" flexItem sx={{borderColor: 'background.main', margin: 7, display: {xs: 'none', md: 'flex'}}} />

                    <Divider aria-hidden="true" variant="middle" sx={{borderColor: 'background.main', margin: 7, display: {xs: 'block', md: 'none'}}} />

                    <Box>
                        <img alt="Headshot of Philip Hoare" src={philPhoto} width={250} height={250}  style={{borderRadius: 10, borderWidth: '3px', borderStyle: 'solid', color: "#8F006D"}} />
                        <Box sx={{marginBottom: 4, margin: 1}}>
                            <Typography variant="h2">Philip Hoare</Typography>
                            <Divider aria-hidden="true" variant="middle" sx={{marginBottom: 1, borderColor: '#BBBBBB'}} />
                            <Typography variant="p">Philip is an inclusive creator who always strives for accessibility in his projects, and he can often be found campaigning for disability inclusion and aid across the board. With a background in computer science and design, he drives the technology side of the Purple Collective from managing emails to running the website. <br /> <br /> Philip is a wheelchair user and, through his role at the Purple Collective, aims to remove barriers for all disabled people, to build a world that is accepting of, and can accommodate to, everyone.</Typography>
                            
                        </Box>
                    </Box>
                    
                </Box>
            </Container>

            <Container sx={{width: '85vw', marginTop: 7, marginBottom: 4}} maxWidth={false} disableGutters>
                <Typography variant="h1" sx={{color: 'primary.dark', marginBottom: 5}}>Frequently Asked Questions</Typography>
                <FAQ />
            </Container>

            <Container sx={{width: '85vw', marginTop: 3, marginBottom: 14}} maxWidth={false} disableGutters>
                <Box sx={{marginLeft: 'auto', marginRight: 'auto', marginTop: {xs: 6, sm: 18}, width: '85vw'}}>
                    <Typography variant="h2" sx={{color: 'primary.dark'}}>Join Us Today</Typography>
                    <EmailSignUpForm />
                    
                </Box>
            </Container>
        </>
    )
}

export default About;