import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid #8F006D`,
  '&:lastChild': {
    borderBottom: 0
  }
}));

const LastAccordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
  }));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(143, 0, 109, 0.2)', 
  textAlign: 'left'
}));

export default function FAQ() {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant='p'>What types of campaigns does the Purple Collective support?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='p' sx={{fontSize: {xs: '1.1rem', sm: '1.1rem'}}}>
          We support a wide range of campaigns, including accessible housing, social care, and accessible air travel. Our goal is to address pressing issues that affect the disabled community and promote greater inclusion and accessibility.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography variant='p'>Is there a membership fee to join the Purple Collective?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='p' sx={{fontSize: {xs: '1.1rem', sm: '1.1rem'}}}>
          No, joining the Purple Collective is free. We welcome anyone who is passionate about disability advocacy and wants to make a difference.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography variant='p'>What kind of actions will I be asked to take?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='p' sx={{fontSize: {xs: '1.1rem', sm: '1.1rem'}}}>
          Actions range from signing petitions, sharing information on social media, writing letters to policymakers, to participating in local events. Each task is designed to be straightforward and manageable, ensuring you can easily fit them into your schedule.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography variant='p'>How do I know if my actions are making a difference?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='p' sx={{fontSize: {xs: '1.1rem', sm: '1.1rem'}}}>
          We provide regular updates on the progress and impact of our campaigns. By participating, you’ll receive feedback on how your actions are contributing to the overall goals and making a tangible difference.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography variant='p'>How often will I receive action items?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='p' sx={{fontSize: {xs: '1.1rem', sm: '1.1rem'}}}>
          You will receive action items regularly via email, typically on a weekly basis. We ensure that each task is timely and relevant to ongoing campaigns.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography variant='p'>What support is available if I have questions or need help with an action?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='p' sx={{fontSize: {xs: '1.1rem', sm: '1.1rem'}}}>
          Please email us at <a href='mailto:hello@purplecollectiveglobal.com'>hello@purplecollectiveglobal.com</a>, and one of our team members will get back to you as soon as possible.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <Typography variant='p'>Can I suggest a campaign or action?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='p' sx={{fontSize: {xs: '1.1rem', sm: '1.1rem'}}}>
          Absolutely! We welcome suggestions from our community. If you have an idea for a campaign or a specific action, please get in touch with us through our website.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <LastAccordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
          <Typography variant='p'>How can I stay updated on the latest news and campaigns from the Purple Collective?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='p' sx={{fontSize: {xs: '1.1rem', sm: '1.1rem'}}}>
          By subscribing to our newsletter and following us on social media, you can stay informed about the latest news, campaigns, and updates. We regularly share information and engage with our community through these channels.
          </Typography>
        </AccordionDetails>
      </LastAccordion>
    </div>
  );
}