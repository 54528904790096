import { Box, Button, Container, Divider, Typography, IconButton } from "@mui/material";
import React from "react";
import purpleCollectiveLogoBigWhite from "./images/purple-collective-logo-big-white.png";

const SiteFooter = () => {

    return (
        <Container aria-label="footer" sx={{height: {xs: 210, sm: 88}, backgroundColor: 'secondary.main', color: 'background.main', display: 'flex', flexDirection: 'column-reverse', justifyContent: 'center', marginTop: 'auto'}} maxWidth={false} >
            <Box sx={{display: {xs: 'block', sm: 'flex'}, justifyContent: 'center', alignItems: 'center'}}>
                <Typography variant="p" sx={{display: {xs: 'block', sm: 'flex'}}}>Copyright © 2024 The Purple Collective</Typography>
                <Divider aria-hidden="true" orientation="vertical" flexItem sx={{margin: 2, borderColor: 'background.main', display: {xs: 'none', sm: 'flex'}}}/>
                <Button href="/privacy-policy" sx={{display: {xs: 'block', sm: 'flex'}, color: 'background.main', margin: 0, padding: 0, textDecoration: 'underline', '&:hover': {color: 'rgba(255,255,255,0.7)', textDecoration: 'underline'}, textWrap: 'nowrap'}}>Privacy Policy</Button>
                <Divider aria-hidden="true" orientation="vertical" flexItem sx={{margin: 2, borderColor: 'background.main', display: {xs: 'none', sm: 'flex'}}}/>
                <IconButton href="/" sx={{margin: {xs: 2, sm: 0}, padding: 0, '&:hover': {backgroundColor: 'transparent'}}} disableRipple disableFocusRipple>
                <img alt="A logo with a megaphone next to the letter C. On the left of the logo is the word 'purple' and on the right is the word 'collective'" src={purpleCollectiveLogoBigWhite} height={60} />
                </IconButton>
            </Box>
            
        </Container>
    )
}

export default SiteFooter;