import React, { useState } from "react";
import { Drawer, Box, List, ListItem, ListItemButton, ListItemText, Typography, IconButton, Divider } from "@mui/material";
import { Instagram } from "@mui/icons-material";

const PageNavDrawer = ( { toggleDrawer, open, setOpen } ) => {

    const listButton = (text) => {
        let linkText = "/";
        if (text != "Home") linkText += text.toLowerCase();

        if ("/" + text.toLowerCase() == window.location.pathname || (text == 'Home' && window.location.pathname == "/")) return (
            <ListItemButton href={linkText} sx={{color: 'primary.dark', width: '100vw', '&:hover': {color: 'primary.dark', boxShadow: 'none', backgroundColor: 'background.main'}}} disableRipple disableTouchRipple>
                <Typography variant="h2" aria-label={"go to page " + text} textAlign={"center"} sx={{marginLeft: 'auto', marginRight: 'auto'}}>{text}</Typography>

            </ListItemButton>
        );

        else return (
            <ListItemButton href={linkText} sx={{color: 'primary.light', width: '100vw', '&:hover': {color: 'primary.dark', boxShadow: 'none', backgroundColor: 'background.main'}}} disableRipple disableTouchRipple>
                <Typography variant="h2" textAlign={"center"} sx={{marginLeft: 'auto', marginRight: 'auto'}}>{text}</Typography>

            </ListItemButton>
        );
    }


    const openExternalInstagramTab = () => {
        window.open('https://www.instagram.com/purplecollectiveglobal/', '_blank');
    }

    const DrawerList = (
        <Box sx={{height: '100vh', width: "100vw", display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <List sx={{marginTop: 12}}>
                {['Home', 'About'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        
                        {listButton(text)}
                    </ListItem>
                ))}
            </List>
            
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Divider variant="middle" sx={{marginBottom: 1, borderColor: 'secondary.main'}} />
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '70vw', marginLeft: 'auto', marginRight: 'auto', marginBottom: 3}}>
                    <IconButton onClick={openExternalInstagramTab} sx={{color: 'primary.light', '&:hover': {color: 'primary.dark', backgroundColor: 'background.main'}, alignSelf: 'baseline'}} aria-label="instagram">
                        <Instagram sx={{height: 44, width: 44}} />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Drawer open={open} onClose={toggleDrawer(false)} anchor="right" sx={{display: {xs: 'flex', md: 'none'}}}>
            {DrawerList}
        </Drawer>
    )
}

export default PageNavDrawer;