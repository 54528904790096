import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Home';
import NavBar from './NavBar';
import Theme from './Theme';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import SiteFooter from './SiteFooter';
import About from './About';
import PrivacyPolicy from './PrivacyPolicy';

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Router>
        <div className="App">
          <header>
            <NavBar aria-label="" />
          </header>
          <Routes>
            <Route path='/' Component={Home} />
            <Route path='/about' Component={About} />
            <Route path='/privacy-policy' Component={PrivacyPolicy} />
          </Routes>

          <footer>
            <SiteFooter />
          </footer>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
