import { createTheme } from "@mui/material";
import BebasNeue from './fonts/BebasNeue-Regular.ttf';
import AsapCondensedMedium from './fonts/Asap_Condensed/AsapCondensed-Medium.ttf';

const BebasNeueFont = {
    fontFamily: 'Bebas Neue',
    fontWeight: 400,
    fontStyle: 'normal',
    src: `url(${BebasNeue}), format('ttf')`
}

const AsapCondensedMediumFont = {

    fontFamily: 'Asap Condensed',
    fontStyle: 'normal',
    fontWeight: 500,
    src: `url(${AsapCondensedMedium}), format('ttf')`
}

const Theme = createTheme({
    palette: {
        primary: {
            main: '#8F006D',
            light: '#BA7FBA', // #D397D8, is too light and does not contrast
            dark: '#470063'
        },
        secondary: {
            main: '#12355B',
            light: '#184677'
        },
        background: {
            main: '#FFFFFF'
        }
        
        
    },
    typography: {
        fontFamily: 'Asap Condensed',
        fontWeight: 500,

        h1: {
            fontFamily: 'Bebas Neue',
            fontWeight: 900,
            fontSize: '7rem',

            
        },

        h2: {
            fontFamily: 'Bebas Neue'
        },

        h3: {
            fontFamily: 'Asap Condensed',
            fontWeight: 500,
            fontSize: '2.2rem'
        },

        h4: {
            fontFamily: 'Bebas Neue',
            fontWeight: 900,
            fontSize: '2.4rem',
            alignSelf: 'self-end'
        },

        p: {
            fontFamily: 'Asap Condensed',
            fontWeight: 500,
            fontSize: '1.3rem'
        },

        button: {
            fontFamily: 'Bebas Neue',
            fontWeight: 600,
            fontSize: '1.5rem',
        }, 
        
        
    },
    
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: [
                    {'@font-face': BebasNeueFont},
                    {'@font-face': AsapCondensedMediumFont}
                ],
            },
        },
    },
});

Theme.typography.h1 = {
    fontFamily: 'Bebas Neue',
    fontWeight: 900,
    fontSize: '7rem',

    [Theme.breakpoints.down('lg')]: {
        fontSize: '6rem'
    },

    [Theme.breakpoints.down('md')]: {
        fontSize: '4.5rem'
    },
    
    [Theme.breakpoints.down('sm')]: {
        fontSize: '2.6rem'
    }
}

Theme.typography.h3 = {
    fontFamily: 'Asap Condensed',
    fontWeight: 500,
    fontSize: '2.2rem',

    [Theme.breakpoints.down('lg')]: {
        fontSize: '2rem'
    },

    [Theme.breakpoints.down('md')]: {
        fontSize: '1.7rem'
    },

    [Theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem'
    }
}

Theme.typography.h2 = {
    fontFamily: 'Bebas Neue',
    fontWeight: 900,
    fontSize: '3rem',

    [Theme.breakpoints.down('sm')]: {
        fontSize: '2rem'
    }
}


export default Theme;